<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        إدارة الموقع
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>

    <validation-observer ref="addCityFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="المدينة"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCityForm.city_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id"
                >
                  <template #no-options="{ search, searching, loading }">
                    يتم تحميل البيانات ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="العنوان"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="العنوان"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCityForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="الوصف"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'descriptionEditor'"
                  v-model="addCityForm.description"
                />
           
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                الصورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewEl.src = Tabimage;
                      addCityForm.image = '';
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Icon صورة "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Icon Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCityForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- <b-form-group
                      label="icon image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2">
                      <b-form-input
                        id=" alt_icon_image"
                        v-model="addCityForm.alt_icon_image" />
                    </b-form-group> -->
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col
                md="12"
                class="mb-2"
              >
                <GmapAutocomplete
                  class="form-control"
                  placeholder="البحث عن موقع  "
                  @place_changed="setPlace"
                />
              </b-col>
              <b-col
                md="12"
                class="mb-2"
              >
                <GmapMap
                  :center="center"
                  :zoom="10"
                  style="width: 100%; height: 500px"
                  @click="handleMapClick"
                >
                  <GmapMarker
                    v-for="(m, index) in marker"
                    :key="index"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @dragend="updateMaker"
                    @click="center = m.position"
                  />
                </GmapMap>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              جار الحفظ...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import Editor from '@tinymce/tinymce-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import QuillEditor from "@core/components/editor/Editor";
export default {
  setup() {
    // const store = useStore();
    const center = ref({ lat: 51.093048, lng: 6.84212 })
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const currentPlace = ref()
    const isLoading = ref(false)
    const Tabimage = ref('media/svg/files/blank-image.svg')

    const citiesList = ref([])
    const places = ref([])
    const marker = ref([
      {
        position: { lat: 11.0, lng: 11.0 },
      },
    ])
    const handleMarkerDrag = e => {
      marker.value.position = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    }

    const handleMapClick = e => {
      marker.value.position = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    }

    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })
    const addMarker = () => {
      if (currentPlace.value) {
        const markerPlace = {
          lat: currentPlace.value.geometry.location.lat(),
          lng: currentPlace.value.geometry.location.lng(),
        }
        console.log(marker)
        // marker.value.push({position :markerPlace});
        marker.value[0].position = markerPlace
        // this.places.push(currentPlace.value);
        console.log(marker.value)
        places.value.push(currentPlace.value)

        console.log(center.value)
        center.value = markerPlace
        currentPlace.value = null
        console.log(center.value)
      }
    }
    const setPlace = place => {
      console.log(place)
      currentPlace.value = place
      addMarker()
    }

    const categoriesList = ref([])
    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })
    const addCityForm = reactive({
      title: '',
      city_id: '',
      description: '',
      image: '',
    })
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )

    const form = ref()

    const addCityFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCityFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('description', addCityForm.description)
          formData.append('city_id', addCityForm.city_id)
          formData.append('title', addCityForm.title)
          formData.append('link_id', addCityForm.link_id)
          formData.append('lat', marker.value[0].position.lat)
          formData.append(' long', marker.value[0].position.lng)
          formData.append('image', addCityForm.image)
          isLoading.value = true

          store
            .dispatch('cities/addlocation', formData)
            .then(response => {
              isLoading.value = false
              refPreviewEl.value.src = 'media/svg/files/blank-image.svg'

              form.value.reset()

              Vue.swal({
                title: 'تم الاضافة بنجاح ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              isLoading.value = false
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const updateMaker = event => {
      console.log('updateMaker, ', event.latLng.lat())
      marker.value[0].position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
    }
    return {
      updateMaker,
      addCityForm,
      places,
      refInputEl,
      refPreviewEl,
      setPlace,
      addMarker,
      currentPlace,
      inputImageRenderer,
      isLoading,
      addCityFormvalidate,
      categoriesList,
      form,
      handleMapClick,
      handleMarkerDrag,
      center,
      marker,
      save,
      required,
      email,
      citiesList,
      Tabimage,
      // sort
    }
  },

  components: {
    BCard,
    Editor,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    QuillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
.mb-2.col-12 {
    margin: 9px !important;
}
</style>
